var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("project-invoice-report", {
    attrs: {
      report_name: "Profit & Loss Report",
      report_query: _vm.getProfitLossReport,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }