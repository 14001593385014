<template>
    <div class="value-display" :class="breakeven_status">
        <div v-if="icon" class="indicator">
            <i :class="icon" />
            <span class="label">{{ percentage }}%</span>
        </div>
        <span class="value" :class="{bold}">{{ value | currency }}</span>
    </div>
</template>

<script>
export default {
    name: 'breakeven-value',
    props: {
        breakeven: {
            type: Number,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
        bold: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        breakeven_status() {
            return this.value >= this.breakeven ? 'above' : 'below';
        },
        icon() {
            if (this.value > this.breakeven) {
                return 'icon el-icon-arrow-up';
            }
            if (this.value < this.breakeven) {
                return 'icon el-icon-arrow-down';
            }
            return null;
        },
        percentage() {
            const value =
                ((this.value / this.breakeven) * 100).toFixed(0) - 100;
            return value > 0 ? `+${value}` : value;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.value-display {
    padding: 10px;
    border-radius: 4px;
    color: $black;
    display: flex;
    flex-direction: row;
    align-items: center;

    .indicator {
        flex: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .label {
            font-size: 7px;
            font-weight: bolder;
        }
    }

    .value {
        flex: 1;
        text-align: right;

        &.bold {
            font-weight: bold;
        }
    }

    &.above {
        background-color: rgba($green-dark, 0.2);

        .indicator {
            .icon,
            .label {
                color: $green-dark;
            }
        }
    }

    &.below {
        background-color: rgba($red-soft, 0.1);

        .indicator {
            .icon,
            .label {
                color: $red;
            }
        }
    }
}
</style>
