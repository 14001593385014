var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "actions-buttons",
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.tabChanged },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "Profit & Loss", name: "_profitloss" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "Cashflow", name: "_cashflow" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide", mode: "out-in" } },
        [
          _vm.activeTab == "_profitloss"
            ? _c("ReportTab_profitloss", { key: "profitloss" })
            : _vm._e(),
          _vm.activeTab == "_cashflow"
            ? _c("ReportTab_cashflow", { key: "cashflow" })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }