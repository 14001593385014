<template>
    <div>
        <actions-buttons>
            <div class="report_options">
                <el-select
                    class="year-mode-selector"
                    size="mini"
                    v-model="year_mode"
                >
                    <el-option value="full" label="All invoices" />
                    <el-option value="accepted" label="Accepted only" />
                    <el-option value="invoiced" label="Invoiced only" />
                </el-select>
                <el-select
                    class="total-mode-selector"
                    size="mini"
                    v-model="total_mode"
                >
                    <el-option value="monthly" label="Monthly totals" />
                    <el-option value="accruing" label="Accruing totals" />
                </el-select>
            </div>
            <el-tabs v-model="activeTab" @tab-click="tabChanged">
                <el-tab-pane label="Profit & Loss" name="_profitloss" />
                <el-tab-pane label="Cashflow" name="_cashflow" />
            </el-tabs>
        </actions-buttons>

        <transition name="slide" mode="out-in">
            <ReportTab_profitloss
                v-if="activeTab == '_profitloss'"
                key="profitloss"
                :year_mode="year_mode"
                :total_mode="total_mode"
            />
            <ReportTab_cashflow
                v-if="activeTab == '_cashflow'"
                key="cashflow"
                :year_mode="year_mode"
                :total_mode="total_mode"
            />
        </transition>
    </div>
</template>

<script>
import ActionsButtons from '@/components/generic/ActionsButtons';

import ReportTab_cashflow from '@/pages/reports/tabs/ReportTab_cashflow';
import ReportTab_profitloss from '@/pages/reports/tabs/ReportTab_profitloss';

export default {
    name: 'component-detail',
    components: {
        ActionsButtons,
        ReportTab_cashflow,
        ReportTab_profitloss,
    },
    data() {
        return {
            activeTab: '_profitloss',
            year_mode: 'full',
            total_mode: 'monthly',
        };
    },
    watch: {
        $route(to) {
            this.activeTab = to.meta.tab;
        },
    },
    created() {
        this.activeTab = this.$router.currentRoute.meta.tab;
    },
    methods: {
        tabChanged(event) {
            this.$router.replace({
                name: `report${event.name}`,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.report_options {
    display: flex;
    gap: 5px;
    .year-mode-selector {
        width: 130px;
    }
    .total-mode-selector {
        width: 140px;
    }
}
.slide {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateX(5px);
    }
}
</style>
